import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authSrv: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      

    if (state.url === '/login' || state.url === '/signup' || state.url === '/home') {
      return this.validateLoginAndRegister();
    } else {
      return this.validateDashboardAccess();
    }
  }

  validateLoginAndRegister(): boolean {
    if (
      this.authSrv.isLoggedIn() != null ||
      this.authSrv.readUserSessionData() != false
    ) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }

  validateDashboardAccess(): boolean{
    if (
      this.authSrv.isLoggedIn() != null ||
      this.authSrv.readUserSessionData() != false
    ) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
